import React, { Component } from 'react';
import { MenuItems } from './MenuItems';
import { Link } from 'gatsby';
import './Navbar.css';
import { Button } from './Button.js';
import sds from '../../../static/images/sds.png';
import '../../../node_modules/font-awesome/css/font-awesome.min.css';
import '../../../node_modules/font-awesome/css/font-awesome.css';
import Helmet from 'react-helmet';

function disableScroll() {
  const navbar = document.querySelector('.nav-menu');
  const body = document.querySelector('.main-content');
  const temp = document.querySelector('.NavbarItems');
  if (!navbar.classList.contains('active')) {
    // console.log(body);
    body.setAttribute('style', 'display:none');
    document.body.style.display = 'block';
    document.body.style.height = '90vh';
	temp.setAttribute('style','height:fit-content');
  } else {
    body.removeAttribute('style');
    document.body.style.display = '';
    document.body.style.height = 'auto';
	temp.setAttribute('style','height:80px');
    // body.setAttribute('style', 'display:block');
  }
}

class Navbar extends Component {
  state = { clicked: false };

  handleClick = () => {
    this.setState({ clicked: !this.state.clicked });
  };

  render() {
    return (
      <nav className="NavbarItems">
        <Helmet>
          <title>SDS COEP</title>
          <meta name="title" content="Software Development Section, COEP" />
        </Helmet>
        <h1 className="navbar-logo">
          <a href="/">
            <img
              src={sds}
              alt="logo"
              style={{ width: '60px', height: '70px' }}
            ></img>
          </a>
        </h1>
        <div
          className="menu-icon"
          onKeyPress={this.handleClick}
          onClick={() => {
            this.handleClick();
            disableScroll();
          }}
          role="button"
          tabIndex="0"
        >
          <i
            key={this.state.clicked}
            className={this.state.clicked ? 'fa fa-times' : 'fa fa-bars'}
          ></i>
        </div>
        <ul className={this.state.clicked ? 'nav-menu active' : 'nav-menu'}>
          {MenuItems.map((item, index) => {
            return (
              <div className="try" key={index}>
                <li key={index} className={item.cLi}>
                  <Link
                    className={item.cName}
                    style={{ textDecoration: 'none' }}
                    to={item.url}
                  >
                    {item.title}
                  </Link>
                </li>
              </div>
            );
          })}
        </ul>

        <div className="button-sign">
          <Button>
            <Link to="/contact/">Contact Us</Link>
          </Button>
        </div>
      </nav>
    );
  }
}

export default Navbar;
