
import React, { Component } from "react";
import Parallax from 'parallax-js';
import "./parallax.css";


class Bg extends Component {
  componentDidMount() {
    const scene = document.getElementById('scene');
    new Parallax(scene);

    const scene2 = document.getElementById('scene2');
    new Parallax(scene2);
  }

  render() {
    return (
      <>
        <div className="parallax1">
          <div className="overlay"></div>
          <ul id="scene" >
            <li className="layer" data-depth="0.9" id="desktop">
              <img alt="parallax-desktop" src={'/layers/parallaxW.png'} className="_filter" style={{ width: '80vw', position: 'relative', left: '4%'}} />
            </li>
          </ul>
        </div>

        <div className="parallax2">
          <div className="overlay"></div>
          <ul id="scene2">
            <li className="layer" data-depth="0.9" id="mobile">
              <img alt="parallax-mobile" src={'/layers/mobile.png'} className="_filter" style={{position: 'relative', width: '140%', left: '-20%'}}/>
            </li>
          </ul>
        </div>
      </>

    )
  }
}

export default Bg;
