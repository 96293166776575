import React, { Component } from 'react';
import './Footer.css';
import {
  FaFacebook,
  FaLinkedin,
  FaTwitter,
  FaArrowUp,
  FaGitlab,
} from 'react-icons/fa';
import Emoji from 'a11y-react-emoji';

class Footer extends Component {
  render() {
    var scrollTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    // if (this.props.data) {
    //   var networks = this.props.data.social.map(function (network) {
    //     return (
    //       <li key={network.name}>
    //         <a href={network.url}>
    //           <i className={network.className}></i>
    //         </a>
    //       </li>
    //     );
    //   });
    // }

    return (
      <footer>
        <div className="row">
          <div className="twelve columns">
            <h2>Get in Touch</h2>
            <a href="https://portal.sdscoep.club/" target="_blank">
              Have a Project ? Click Here!
            </a>
          </div>
        </div>
        <div className="row">
          <div className="twelve columns">
            <ul className="social-links">
              <li key="facebook">
                <a href="https://www.facebook.com/sdscoep" target="_blank">
                  <i>
                    <FaFacebook />
                  </i>
                </a>
              </li>
              <li key="linkedin">
                <a
                  href="https://www.linkedin.com/company/sdscoep/"
                  target="_blank"
                >
                  <i>
                    <FaLinkedin />
                  </i>
                </a>
              </li>
              <li key="twitter">
                <a href="https://twitter.com/sdscoep" target="_blank">
                  <i>
                    <FaTwitter />
                  </i>
                </a>
              </li>
              <li key="github">
                <a href="https://gitlab.com/sdscoep" target="_blank">
                  <i>
                    <FaGitlab />
                  </i>
                </a>
              </li>
            </ul>

            <p>© 2024 SDS, COEP. All Rights Reserved. Designed by SDS</p>

            <p>
              Made with
              <Emoji symbol=" ❤️ " label="heart" /> in COEP
            </p>
          </div>
          <div id="go-top">
            <a
              className="smoothscroll"
              title="Back to Top"
              href="#home"
              onClick={() => scrollTop()}
            >
              <i style={{ color: '#000' }}>
                <FaArrowUp />
              </i>
            </a>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
