export const MenuItems = [
  {
    title: 'Home',
    url: '/',
    cName: 'nav-links',
    cLi: 'nav-li',
  },
  {
    title: 'About',
    url: '/about/',
    cName: 'nav-links',
    cLi: 'nav-li',
  },
  {
    title: 'Projects',
    url: '/projects/',
    cName: 'nav-links',
    cLi: 'nav-li',
  },
  {
    title: 'Sessions',
    url: '/sessions/',
    cName: 'nav-links',
    cLi: 'nav-li',
  },
  {
    title: 'Team',
    url: '/team/',
    cName: 'nav-links',
    cLi: 'nav-li',
  },
  {
    title: 'Contact Us',
    url: '/contact/',
    cName: 'nav-links-mobile',
  },
];
